import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/Credit_confirmVAQ_CCV1/css/confirm_credit.scss";
import "../../assets/Credit_confirmVAQ_CCV1/css/Modalstyle.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { userInfo } from "../../Hooks/userInfo";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { Api } from "../../api/Api";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import { usePageLoad } from "../../Hooks/usePageLoad";
import { getPageName } from "../../Constants/PageNames";
import enrichmentImg from "../../assets/Credit_confirmVAQ_CCV1/img/enrichment.png";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { useAddressApi } from "../../Hooks/useAddressApi";

const Credit_ConfirmVAQ_CCV1 = () => {
    const { visitorParameters } = useContext(VisitorParamsContext);
    const history = useHistory();
    const splitName = localStorage.getItem("split_name");
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const { savePageLoad, calledOnce } = usePageLoad();
    const queryString = localStorage.getItem("queryString");
    const uuid = visitorParameters.visitor_parameters.uuid;
    const [showModal, setShowModal] = useState(false);
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { addressCheck } = useAddressApi();

    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            queryString,
            formdata
        );
    };

    useEffect(() => {
        if (!!uuid && !calledOnce) {
            const data = {
                uuid,
                page_name: getPageName("credit_confirm"),
                status: "visited",
                source: "regular",
            };
            (async () => {
                await savePageLoad(
                    visitorParameters.visitor_parameters,
                    data,
                    "visited_status",
                    splitName,
                    queryString,
                    visitorParameters.data
                );
            })();
        }
    }, [uuid, calledOnce]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 3000); 

        return () => clearTimeout(timer); 
    }, []);


    const UpdateLeadstatus = () => {
        const data = {
            uuid,
            page_name: getPageName("credit_confirm"),
            status: "completed",
            source: "regular",
        };

        savePageLoad(
            visitorParameters.visitor_parameters,
            data,
            "visited_status",
            splitName,
            queryString,
            visitorParameters.data
        );
    };


    const handleCreditCheck = (e) => {
       
        let question_data = {};
        if (e.target.value === "328") {
            GtmDataLayer({
                'question': 'Stay Informed'
              });
            question_data = {
                question_id: 119,
                option_id: 328,
                answer_text: null,
                input_answer: "",
            };
            addressCheck(visitorParameters.visitor_parameters, formdata);
            UpdateLeadstatus();
            storeQuestionnire(question_data);

            history.push("/CreditAnalyseVAQ_CCV1?uuid=" + visitorParameters.visitor_parameters.uuid);
        } else if (e.target.value === "329") {
            GtmDataLayer({
                'question': 'Stay Informed'
              });
            question_data = {
                question_id: 119,
                option_id: 329,
                answer_text: null,
                input_answer: "",
            };
            storeQuestionnire(question_data);
            UpdateLeadstatus();
            history.push("/QuestionaireVAQ_CCV1?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
    };


    return (
        <>
            <GetVisitorsParams />
            <div className="credit_confirm">
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-12 col-md-12 col-lg-12 logo text-center">
                                <img src="assets/VAQ_ID_UPLOAD/img/logo-white.png" alt="" />
                            </div>
                        </div>
                    </div>
                </header>
                <section className="content">
                    <div className="container text-center">
                        <div className={`loader-wrap text-center text-light`}>
                            <div>
                                <img
                                    height={80}
                                    src="assets/VAQ_ID_UPLOAD/img/loader-review2.svg"
                                    alt=""
                                />
                                <h6 className="mt-3">Please Wait ...</h6>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ***** Modal Section Credit Confirm *************** */}

            <div className="confirm_V1Modal">
                <div className={` modal fade ${showModal ? "show" : "hide"}`} id="staticBackdrop" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm mx-lg-auto mx-md-auto mx-sm-auto mx-sm-2 mx-5">
                        <div className="modal-content">
                            <div className="modal-body position-relative text-black p-lg-4 p-md-4 p-sm-4 p-4">
                                <div className="modal-set-header hd-two text-center mb-3">
                                    <div className="mt-4">
                                        <img height="90px" src={enrichmentImg} alt="Claim Opportunity" />
                                    </div>
                                    <h2 class="my-3 fw-bold">
                                    Stay informed about New Claim Opportunities
                                    </h2>
                                    <div className="mb-4">
                                        <p className="px-2 ">
                                            We will review your credit file/ Bank statements to
                                            identify any other claims you could be eligible for,
                                            such as unaffordable lending based on your data. Do you
                                            consent to this check for potential additional claims?
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <ul className="btn-option ul-agree p-0 m-0 mb-4">
                                        <li className="mb-3">
                                            <div className="option_but text-center" onClick={handleCreditCheck}>
                                                <input
                                                    type="radio"
                                                    id="328"
                                                    value="328"
                                                    name="credit_check1"
                                                />
                                                <label for="328" className="justify-content-center agree text-white">
                                                    Yes, I agree
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="option_but" onClick={handleCreditCheck}>
                                                <input
                                                    type="radio"
                                                    id="329"
                                                    value="329"
                                                    name="credit_check2"
                                                />
                                                <label for="329" className="justify-content-center progress-button" >
                                                    No, I do not agree
                                                </label>
                                            </div>
                                        </li>
                                    </ul>





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};
export default Credit_ConfirmVAQ_CCV1;
