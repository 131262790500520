import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";

import trustpilot from "../../../../assets/UFC_DC_V1/img/trustpilot.png";
import ratingstar from "../../../../assets/UFC_DC_V1/img/rating-star.png";

const TrustPilotCarousel = () => {
  return (
    <section className="reviewsection mb-5">
      <div className="container">
        <div className="panel rounded-4  p-5 ">
          <div className="mb-4">
            <img src={trustpilot} alt="" />
          </div>
          <Swiper
            modules={[Pagination, Autoplay]}
            // pagination={{ clickable: true }}
            slidesPerView={3}
            spaceBetween={30}
            speed={500}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              100: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            className=" testislider fadein-eff"
          >
            <div className="swiper-wrapper pb-3">
              <SwiperSlide>
                <div className="rounded-3 p-4   review-box ">
                  <div className="mb-3 d-flex gap-3 align-items-center">
                    <div>
                      <img className="star" src={ratingstar} alt="" />
                    </div>
                    <div className="verified">
                      {" "}
                      <i className="bi bi-patch-check"></i> Verified{" "}
                    </div>
                  </div>
                  <h3 className="mb-2">
                    Great communication kept me informed…
                  </h3>
                  <p className="reviewSec">
                    Great communication kept me informed all the time . 12
                    months of sorting out my debt with vanquis. Finally sorted
                    on the 5th December this year 2024 debt cleared and
                    compensation. Very happy with how it was handled can't fault
                    with TM . Thanks for everything best Xmas present to
                    receive.
                  </p>

                  <p className="reviewSec">
                    Conan Norton
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="rounded-3 p-4   review-box ">
                  <div className="mb-3 d-flex gap-3 align-items-center">
                    <div>
                      <img className="star" src={ratingstar} alt="" />
                    </div>
                    <div className="verified">
                      {" "}
                      <i className="bi bi-patch-check"></i> Verified{" "}
                    </div>
                  </div>
                  <h3 className="mb-2">TMS legal got me a pay out I didn't…</h3>
                  <p className="reviewSec">
                    TMS legal got me a pay out I didn't expect and they were
                    very professional in all they did. On the down side though I
                    think the commission of 45,% was a bit steep. But hey ho not
                    going to let that reflect that they did a great job
                  </p>

                  <p className="reviewSec">
                    Philip Hill
                  </p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="rounded-3 p-4   review-box ">
                  <div className="mb-3 d-flex gap-3 align-items-center">
                    <div>
                      <img className="star" src={ratingstar} alt="" />
                    </div>
                    <div className="verified">
                      {" "}
                      <i className="bi bi-patch-check"></i> Verified{" "}
                    </div>
                  </div>
                  <h3 className="mb-2">Absolutely fantastic</h3>
                  <p className="reviewSec">
                    Absolutely fantastic, can’t fault them, took loads of stress
                    off my head just before Christmas.. highly recommend
                  </p>

                  <p className="reviewSec">
                    Pai Sade
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="rounded-3  p-4   review-box ">
                  <div className="mb-3 d-flex gap-3 align-items-center">
                    <div>
                      <img className="star" src={ratingstar} alt="" />
                    </div>
                    <div className="verified">
                      {" "}
                      <i className="bi bi-patch-check"></i> Verified{" "}
                    </div>
                  </div>
                  <h3 className="mb-2">A successful outcome!</h3>
                  <p className="reviewSec">
                    Firstly, I was kept informed about where we were up to in my
                    claim ! Secondly the claim was quickly processed without a
                    hitch ! Thirdly, the amount the company was paid from my
                    claim was worth every penny! The person I spoke to explained
                    clearly what was happening and why ! Excellent service!
                  </p>

                  <p className="reviewSec">
                    Julie
                  </p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="rounded-3 p-4   review-box ">
                  <div className="mb-3 d-flex gap-3 align-items-center">
                    <div>
                      <img className="star" src={ratingstar} alt="" />
                    </div>
                    <div className="verified">
                      {" "}
                      <i className="bi bi-patch-check"></i> Verified{" "}
                    </div>
                  </div>
                  <h3 className="mb-2">Claim against Ocean Credit</h3>
                  <p className="reviewSec">
                    Very professional service. Kept me updated throughout the
                    process of the claim and dealt with the claim very quickly
                    too!
                  </p>

                  <p className="reviewSec">
                    Robert Umeokafor
                  </p>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default TrustPilotCarousel;
