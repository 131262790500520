const pageNames = [
  {
    key: "personal",
    value: "basic_details",
  },
  {
    key: "sign",
    value: "signature",
  },
  {
    key: "credit",
    value: "credit_check_analysis",
  },
  {
    key: "questions",
    value: "question_page",
  },
  {
    key: "questionsV2",
    value: "question_page_UFC_DC_V2",
  },
  {
    key: "edit",
    value: "edit_details",
  },
  {
    key: "prevaddress",
    value: "previous_address",
  },
  {
    key: "verify",
    value: "person_verify",
  },
  {
    key: "preview",
    value: "preview_page",
  },
  {
    key: "id",
    value: "id_upload",
  },
  {
    key: "thankyou",
    value: "thankyou_page",
  },
  {
    key: "credit_confirm",
    value: "credit_confirm",
  },
  
];


export const getPageName = (key) => {
  return pageNames.find((page) => page.key === key)?.value;
};
