import React, { useState } from 'react';
import { HighestCreditLimitOption } from '../../../../Constants/Constants'
import SelectBox from '../../../UI/SelectBox';
import GtmDataLayer from '../../../../Utility/GtmDataLayer';

const HighestCreditLimit = ({
    trigger,
    validation,
    getValues,
    saveInputAns,
    backClick,
    clsName
}) => {
    const [error, setError] = useState(false);

    const handleClick = async (e) => {
        let highestBalance = await trigger("creditLimitHigh");
        let errorFlag = 0;
        if (!highestBalance) {
            errorFlag = 1;
        }
        if (errorFlag === 0) {
            setError("");
            GtmDataLayer({
                question: "highest credit limit",
            });
            saveInputAns(e);
        } else {
            setError("Please enter credit limit");
            return false;
        }
    };

    return (

        <div className={`qsSlider slidea7 ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                {" "}
                What is the highest credit limit you had on your overdraft?
            </h2>
            <div className="my-4  forms-body slide-up-now">
                <SelectBox
                    type=""
                    className="form-control"
                    name="creditLimitHigh"
                    OptionValue={HighestCreditLimitOption}
                    onChange={() => setError("")}
                    myRef={validation({
                        required: true,
                    })}
                />
                {error && (
                    <span className="error_msg mt-1">{error}</span>
                )} 
            </div>
            <div className="row  mb-3  ">
                <div className="col-12">
                    <button className="btn-comm2    w-100  next7" type="button"  name="highestcreditLimit" onClick={handleClick} data-quest-id={94} >
                        {/* <span className="btntext"> Continue </span> */}
                        Continue
                    </button>
                </div>
            </div>
            <div className="mt-2 mb-5 text-center">
                <div>
                    {" "}
                    <button className="back-btn mainbckBtn bi bi-chevron-left" id="back6" name='bankActedIrresponsibly' type='button' onClick={backClick}>
                    Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HighestCreditLimit;
