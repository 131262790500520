import "../../assets/multiselectoptions/multiselect.scss";
import {  useEffect, useRef, useState } from "react";
import Select from "react-select";

export default function MultselectOption({
  optionValues,
  setSelectedValues,
  onSelectAnswer,
  nextSlide,
  backClick,
  backSlide,
  dataQuestId,
}) {
  const autofocus = useRef();
  const [selectState, setSelectState] = useState([]);
  const [error, setError] = useState("");
  const [selectValue, setSelectValue] = useState(null);


  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "56px",
    }),
  };

  useEffect(() => {
    setSelectedValues([...selectState.map(({ value }) => ({id: value}))]);
  }, [selectState]);

  const toggleFocus = () => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -135;
      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      window.scroll(0, 0);
    }
  };

  const handleChange = (e) => {
    setError("");
    setSelectState((prevState) => {
      if (prevState.some((obj) => obj.value === e.value)) {
        return prevState; // Return previous state if value already exists
      }
      return [...prevState, e];
    });
    setSelectValue(e);
  };

  const removeOption = (value) => {
    setSelectState((prevState) => {
      const newState = prevState.filter((obj) => obj.value !== value);
      if (newState.length === 0) {
        setSelectValue(null); // Clear the Select component's value
      }
      return newState;
    });
  };

  const handleNextClick = (e) => {
    console.log("handleClilk:", selectState);
    if (selectState.length > 0) {
      setError("");
      onSelectAnswer(e);
      return;
    }
    setError("Please select at least one option");
  };

  return (
    <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 elavate">
      <div className="row">
        <div className="col-lg-8 mb-2" ref={autofocus}>
          <Select
            styles={customStyles}
            options={optionValues}
            className="w-100"
            onChange={handleChange}
            onFocus={toggleFocus}
            name="financeProvider"
            id="format"
            value={selectValue} // Bind the Select component's value to state
            placeholder="Select all that applies"
          />
          {error.length > 0 && <p className="error_msg mt-2">{error}</p>}
          <div className="container my-3">
            {selectState.map(({ label, value }, index) => (
              <div className="selected-options row my-1" key={index}>
                <div className="col-10 col-sm-11">
                  <p className="label">{label}</p>
                </div>
                <button
                  type="button"
                  onClick={() => removeOption(value)}
                  className="col-2 col-sm-1 delete-option text-center d-flex align-items-center"
                >
                  <p>x</p>
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-4 mb-2 z-0">
          <button
            type="button"
            className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
            id="nextscroll"
            onClick={handleNextClick}
            name={nextSlide}
            data-quest-id={dataQuestId}
          >
            Next &#8594;
          </button>
        </div>
      </div>
      <p className="text-center bck-marwid">
        <button
          name={backSlide}
          className="btn btn-secondary backbtn1a"
          id=""
          value=""
          type="button"
          onClick={backClick}
        >
          Go Back
        </button>
      </p>
    </div>
  );
}
