import React, { useState } from "react";
import bannerBg1 from "../../../../assets/UFC_DC_V1/img/banner-bg-1.png";
import bannerBg2 from "../../../../assets/UFC_DC_V1/img/banner-bg-2.png";
import TrustPilotCarousel from "../../../Includes/Layouts/UFC_DC_V1/TrustPilotCarousel";
import AccordSection from "../../../Includes/Layouts/UFC_DC_V2/AccordSection";
import ContentSection from "../../../Includes/Layouts/UFC_DC_V2/ContentSection";
import Header from "../../../Includes/Layouts/UFC_DC_V2/Header";
import bnr_mob from "../../../../assets/UFC_DC_V1/img/bnr-mob.png";
import bank_logo_1 from "../../../../assets/UFC_DC_V1/img/bank-logo-1.png";
import bank_logo_2 from "../../../../assets/UFC_DC_V1/img/bank-logo-2.png";
import bank_logo_3 from "../../../../assets/UFC_DC_V1/img/bank-logo-3.png";
import bank_logo_4 from "../../../../assets/UFC_DC_V1/img/bank-logo-4.png";
import bank_logo_5 from "../../../../assets/UFC_DC_V1/img/bank-logo-5.png";
import bank_logo_6 from "../../../../assets/UFC_DC_V1/img/bank-logo-6.png";
import bank_logo_7 from "../../../../assets/UFC_DC_V1/img/bank-logo-7.png";
import bank_logo_8 from "../../../../assets/UFC_DC_V1/img/bank-logo-8.png";
import bank_logo_9 from "../../../../assets/UFC_DC_V1/img/bank-logo-9.png";
import bank_logo_10 from "../../../../assets/UFC_DC_V1/img/bank-logo-10.png";
import bank_logo_11 from "../../../../assets/UFC_DC_V1/img/bank-logo-11.png";
import bnr_img from "../../../../assets/UFC_DC_V1/img/bnr-img.png";
import highest from "../../../../assets/UFC_DC_V1/img/highest.svg";
import average from "../../../../assets/UFC_DC_V1/img/average.svg";
import { useClaimReg } from "../../../../Hooks/useClaimReg";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const LenderSection = ({
  showLenderSection,
  setShowLenderSection,
  setShowQuestion,
  setShowLoader,
  setSelectedBank,
  uuid,
}) => {
  const [showOtherLender, setShowOtherLender] = useState(false);
  const { updateClaimReg } = useClaimReg();

  const ODMainLenders = [
    { value: "Lloyds", label: "Lloyds", logo: bank_logo_1 },
    { value: "Halifax", label: "Halifax", logo: bank_logo_2 },
    { value: "Barclays", label: "Barclays", logo: bank_logo_3 },
    { value: "Santander", label: "Santander", logo: bank_logo_5 },
    { value: "HSBC", label: "HSBC", logo: bank_logo_4 },
    { value: "Bank of Scotland", label: "Bank of Scotland", logo: bank_logo_6 },
  ];

  const ODMoreLenders = [
    { value: "Royal Bank of Scotland", label: "Royal Bank of Scotland",logo: bank_logo_7 },
    { value: "NatWest", label: "NatWest", logo: bank_logo_8 },
    { value: "TSB", label: "TSB", logo: bank_logo_9 },
    { value: "Yorkshire Bank", label: "Yorkshire Bank", logo: bank_logo_10 },
    { value: "Clydesdale Bank", label: "Clydesdale Bank", logo: bank_logo_11 },
  ];

  const handleLenderSelection = async (lender) => {
    const formattedBank = { value: lender, label: lender };
    setSelectedBank([formattedBank]);
    if (lender) {
      const claimReg = await updateClaimReg(uuid, "UFC_DC_V1", "register_claim");

      if (claimReg.data.status == "Success") {
        setShowLoader("show");
        GtmDataLayer({
          question: "select bank",
        });
        setTimeout(() => {
          setShowLoader("hide");
          setShowLenderSection("hide");
          window.scrollTo(0, 0);
          setShowQuestion("show");
        }, 3000);
      }
    }
  };

  return (
    <>
      <div className={`${showLenderSection}`}>
        <section className="banner  parallax-container">
          <div className="wrap-parallax d-lg-block d-md-block d-sm-none d-none">
            <div className="layer" data-depth="0.3">
              <img className="bg-parallax1 " src={bannerBg1} alt="" />
            </div>
            <div className="layer" data-depth="1">
              <img className="bg-parallax2  " src={bannerBg2} alt="" />
            </div>
          </div>
          <div className="container">
            <Header />
            <div>
              <div className="banner-inner">
                <div className="row pt-lg-5 ">
                  <div className="col-lg-8 col-md-12 col-sm-12  ">
                    <div className="banner-caption">
                      <div className="px-lg-0 px-md-0 px-sm-4 px-4">
                        <div className="row">
                          <div className="col-lg-11 ">
                            <div className="overflow-hidden">
                              <h1 className="fw-bold  drop-in text-lg-start text-md-center text-sm-center text-center">
                                Struggling with Repeated High-Cost Overdraft
                                Fees?
                              </h1>
                            </div>
                            <div className="overflow-hidden">
                              <p className="  pt-0 drop-in-2 subtext text-lg-start text-md-center text-sm-center text-center">
                                Starting your claim is easy and 100% online. You
                                could recover money back (plus interest) if you
                                were mis-sold.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center man-art-mobile d-lg-none d-md-block d-sm-block d-block">
                        <img src={bnr_mob} alt="" />
                      </div>

                      <div className="  overflow-hidden tex-lg-start text-md-start text-sm-center text-center mt-lg-3 mt-md-3 mt-sm-0 mt-3">
                        <div className="home-lenders ">
                          <div className="row">
                            <div className="col-lg-11">
                              <fieldset className="ques1 ">
                                <legend className="fw-bold tex-lg-start text-md-start text-sm-center text-center">
                                Which bank did you have your overdraft with?
                                </legend>
                                <ul className="lend-list m-0 p-0 mt-3 pb-3  ">
                                  {ODMainLenders.map((bank) => (
                                    <li key={bank.value}>
                                      <div
                                        className="lender-items fade-in"
                                        onChange={() =>
                                          handleLenderSelection(bank.value)
                                        }
                                      >
                                        <input
                                          type="radio"
                                          id={bank.value}
                                          value={bank.value}
                                        />
                                        <label
                                          htmlFor={bank.value}
                                          className="chooseLender"
                                        >
                                          <div>
                                            <span className="brand-icon">
                                              <img src={bank.logo} alt="" />
                                            </span>
                                          </div>
                                          <div> {bank.label}</div>
                                        </label>
                                      </div>
                                    </li>
                                  ))}
                                  <li className="w-100">
                                    <div
                                      onClick={() =>
                                        setShowOtherLender("is-open")
                                      }
                                      className="lender-items fade-in"
                                    >
                                      <input type="radio" id="otherLenders" />
                                      <label
                                        htmlFor="otherLenders"
                                        id=""
                                        className="text-center  fw-semibold searchbnk   justify-content-center"
                                      >
                                        {" "}
                                        <span className="me-3">
                                          <i className="bi bi-search"></i>
                                        </span>{" "}
                                        Find more banks
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12  ">
                    <div className="man-art d-lg-block d-md-none d-sm-none d-none">
                      <div className="payouts">
                        <img className="highest" src={highest} alt="" />
                        <img className="average" src={average} alt="" />
                      </div>
                      <img src={bnr_img} height="650" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`more-lender p-3 ${showOtherLender}`}>
            <div className="container home-lenders">
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className="my-2 title">Other Banks</h3>
                <div id="backlender" onClick={() => setShowOtherLender("")}>
                  {" "}
                  <i className="bi bi-chevron-left"></i> Back{" "}
                </div>
              </div>
              <ul className="lend-list m-0 p-0 mt-3 justify-content-center ">
                {ODMoreLenders.map((bank) => (
                  <li key={bank.value}>
                    <div
                      className="lender-items fade-in"
                      onChange={() => handleLenderSelection(bank.value)}
                    >
                      <input type="radio" id={bank.value} value={bank.value} />
                      <label htmlFor={bank.value} className="chooseLender">
                        <div>
                          <span className="brand-icon">
                            <img src={bank.logo} alt="" />
                          </span>
                        </div>
                        <div>{bank.label}</div>
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <div className="body-welcome ">
          {/* <TrustPilotCarousel /> */}
          <ContentSection />
        </div>
        <AccordSection />
      </div>
    </>
  );
};

export default LenderSection;
