import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import Footer from "../../Includes/Layouts/VAQ_CCV1/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import "../../../assets/VAQ_CCV1/scss/VAQ_CCV1.scss";
import AccordSection from "../../Includes/Layouts/VAQ_CCV1/AccordSection.js";
import FormVAQ_CCV1 from "../../Forms/FormVAQ_CCV1.js";

const VAQ_CCV1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_CCV1" />
      <FbViewPixel />
      <div className="VAQ_CCV1">
        <FormVAQ_CCV1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default VAQ_CCV1;
