import React, { useState } from "react";
import diamond from "../../../../assets/UFC_DC_V1/img/diamond.png";
import ssl from "../../../../assets/UFC_DC_V1/img/ssl.png";
import excellent_review from "../../../../assets/UFC_DC_V1/img/excellent-review.svg";
import pound from "../../../../assets/UFC_DC_V1/img/pound.png";
import bg_money_sml from "../../../../assets/UFC_DC_V1/img/bg-money-sml.png";
import logo_white from "../../../../assets/UFC_DC_V1/img/logo-white.png";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const Questions = ({
  showQuestion,
  setShowQuestion,
  setShowPersonalDetails,
  setShowLoader,
  selectedBank,
  setEstimatedRefund,
  setUnaffordableRefund,
  SetQuestion118
}) => {
  const [questOne, setQuestOne] = useState("show");
  const [questTwo, setQuestTwo] = useState("hide");
  const [showRefundSection, setShowRefundSection] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [questThree, setQuestThree] = useState("hide");
  const [bacKbtnId, setBacKbtnId] = useState("1");
  const nextQuestion = (e) => {
    setEstimatedRefund(e.target.value);
    if(e.target.value) {
      GtmDataLayer({
        question: "free online scan",
      });
    }
    
    if(e.target.value == "318"){
      setBacKbtnId(2);
      setQuestTwo("show");
      setQuestOne("hide");
      setBtnDisable(false);
      setShowRefundSection("hide");
    } else if (e.target.value == "319") {
      setShowLoader("show");
      setTimeout(() => {
        setShowLoader("hide");
        setShowQuestion("hide");
        window.scrollTo(0, 0);
        setShowPersonalDetails("show");
      }, 3000);
    }
  };

  // const prevQuestion = () => {
  //   setBtnDisable(true);
  //   setShowRefundSection("");
  //   setQuestOne("show");
  //   setQuestTwo("hide");
  // };

  const prevQuestion = (e) => {
    const value = Number(e.target.value);
    console.log("value", value);
    setBacKbtnId((prevId) => {
      const newId = Math.max(1, prevId - 1); // Prevents going below 1
  
      setBtnDisable(newId === 1); // Disable only if backBtnId is 1
  
      return newId;
    });
    switch (value) {
      case 1:
        setBtnDisable(true);
        setShowRefundSection("");
        setQuestOne("show");
        setQuestTwo("hide");
        break;
      
      case 2:
        setBacKbtnId(1); // Moving back from 2 to 1
        setQuestTwo("hide");
        setQuestOne("show");
        setBtnDisable(false); // Re-enable button if it was disabled
        break;
  
      case 3:
        setBacKbtnId(2); // Moving back from 3 to 2
        setQuestTwo("show");
        setQuestThree("hide"); // Assuming you meant "hide" instead of "three"
        break;
  
      default:
        console.warn("Unexpected value in prevQuestion:", value);
    }
  };
  
  const nextSlide = (e) => {
    setUnaffordableRefund(e.target.value);
    if(e.target.value) {
      GtmDataLayer({
        question: "other oppertunities",
      });
    }
    if(e.target.value == "320"){
      setBacKbtnId(3);
      setQuestTwo("hide");
      setQuestThree("show");
    }else if(e.target.value == "321"){
      setShowLoader("show");
      setTimeout(() => {
        setShowLoader("hide");
        setShowQuestion("hide");
        window.scrollTo(0, 0);
        setShowPersonalDetails("show");
      }, 3000);
    }
  };

  const nextBasicDetails =(e) => {
    SetQuestion118(e.target.value);
    setShowLoader("show");
    setTimeout(() => {
      setShowLoader("hide");
      setShowQuestion("hide");
      window.scrollTo(0, 0);
      setShowPersonalDetails("show");
    }, 3000);
  };
  return (
    <>
      <section className={`banner  parallax-container ${showQuestion}`}>
        <div className="d-lg-none d-md-none d-sm-block d-block money-bg-mobile">
          <img src={bg_money_sml} alt="" />
        </div>
        <div className="container">
          <div>
            <div className="brand">
              <div className="logo pt-3">
                <a href="index.html">
                  {" "}
                  <img src={logo_white} height="60" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="container pt-5 px-lg-2 px-md-2 px-sm-0 px-0  ">
          <div className="inner-section   ">
            <div className="d-flex hd-lop slide-up-now">
              <div className="pe-4">
                {" "}
                <img height="80" src={diamond} alt="" />{" "}
              </div>
              <div>
                <h1 className="fw-bold">
                  Exclusive Access: Our Premium Financial Assessment Is Now
                  Available —FREE, For a Limited Time!
                </h1>
                <div className="d-lg-block d-md-block d-sm-none d-none">
                  <p>
                    This service, typically reserved for top-tier financial
                    advocates, is now being offered to  you at no cost for a
                    limited time. 
                  </p>
                </div>
              </div>
            </div>
            <div className="d-lg-none d-md-none d-sm-block d-block sub-text mb-4 slide-up-now">
              <p>
                This service, typically reserved for top-tier financial
                advocates, is now being offered to  you at no cost for a limited
                time. 
              </p>
            </div>

            <div className="body-questionnaire mb-4">
              <div className="inner rounded-4">
                <div className="swiper qsSlider slide-up-now">
                  <div className="swiper-wrapper">
                    <div className={`swiper-slide slide-up-now ${questOne}`}>
                      <h2 className="qs-title">
                        {/* {`Would you like to run a free, online scan to calculate
                        the estimated refund from ${
                          selectedBank[0] ? selectedBank[0].label : ""
                        } for this overdraft
                        facility?`} */}
                        {`Would you like to see if you could be eligible for a refund from your ${
                          selectedBank[0] ? selectedBank[0].label : ""
                        } overdraft?  Take our free scan now.`}
                      </h2>
                      <div className="ms-lg-5 ms-md-3 ms-0 ps-lg-4 ps-md-3 ps-sm-2 ps-0 pt-lg-2 pt-md-4 pt-sm-2 pt-2 below-text">
                        <div className="mt-2 d-flex align-items-center">
                          <div className="me-3">
                            <img height="25" src={pound} alt="" />
                          </div>

                          <div>
                            {" "}
                            Our Highest Overdraft Refund is - <strong>£3,024</strong>
                          </div>
                        </div>
                        <div
                          className={`mt-3 d-flex align-items-center ${showRefundSection}`}
                        >
                          <div className="me-3">
                            <img height="25" src={pound} alt="" />
                          </div>
                          <div>
                            {" "}
                            Our Average Overdraft Refund is - <strong> £993</strong>
                          </div>
                        </div>
                      </div>
                      <ul className="btn-option optinal-items  two-options    ">
                        <li>
                          <div className=" option_but" onClick={nextQuestion}>
                            <input
                              type="radio"
                              id="318"
                              value="318"
                              name="check1"
                            />
                            <label
                              for="318"
                              className=" align-items-center  btn-next"
                            >
                              <div> Yes</div>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div
                            className=" option_but  btn-next"
                            onClick={nextQuestion}
                          >
                            <input type="radio" id="319" name="check1" value="319" />
                            <label for="319" className=" ">
                              <div> No</div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className={`swiper-slide slide-up-now ${questTwo}`}>
                      <h2 className="qs-title  ">
                        {/* Would you also like us to identify other unaffordable
                        refund opportunities along with a FREE PCP check? */}
                        Would you also like us to identify other unaffordable lending opportunities?
                      </h2>
                      <div className="ms-lg-5 ms-md-3 ms-0 ps-lg-4 ps-md-3 ps-sm-2 ps-0 pt-lg-2 pt-md-4 pt-sm-2 pt-2 below-text">
                        <div className="mt-2 d-flex align-items-center">
                          {/* <div className="me-3">
                            <img height="25" src={pound} alt="" />
                          </div> */}

                          {/* <div>
                            {" "}
                           Reclaim Up To <strong>£3,987*</strong> per Mis-Sold Car Finance Agreement
                          </div> */}
                        </div>
                        <div className={"mt-1 d-flex align-items-center"}>
                          <div className="me-3">
                            <img height="25" src={pound} alt="" />
                          </div>
                          <div>
                            {" "}
                            Our Average Refund for Unaffordable credit is<strong> £2,200^</strong>
                          </div>
                        </div>
                      </div>
                      <ul className="btn-option optinal-items  two-options    ">
                        <li>
                          <div className=" option_but " onClick={nextSlide}>
                            <input
                              type="radio"
                              id="320"
                              value="320"
                              name="check2"
                            />
                            <label
                              for="320"
                              className=" align-items-center endQuest"
                            >
                              <div> Yes</div>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div className="option_but" onClick={nextSlide}>
                            <input type="radio" id="321" name="check2" value="321"/>
                            <label for="321" className="endQuest">
                              <div> No</div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className={`swiper-slide slide-up-now ${questThree}`}>
                      <h2 className="qs-title  ">
                        Would you also like a FREE PCP check?
                      </h2>
                      <div className="ms-lg-5 ms-md-3 ms-0 ps-lg-4 ps-md-3 ps-sm-2 ps-0 pt-lg-2 pt-md-4 pt-sm-2 pt-2 below-text">
                        <div className="mt-2 d-flex align-items-center">
                          <div className="me-3">
                            <img height="25" src={pound} alt="" />
                          </div>

                          <div>
                            {" "}
                            Reclaim Up To <strong>£3,987*</strong> per Mis-Sold Car Finance Agreement
                            </div>
                        </div>
                        {/* <div className={"mt-3 d-flex align-items-center"}>
                           <div className="me-3">
                            <img height="25" src={pound} alt="" />
                          </div>
                           <div>
                            {" "}
                            Our Average Refund for Unaffordable credit is<strong> £2,200^</strong>
                            </div> 
                        </div> */}
                      </div>
                      <ul className="btn-option optinal-items  two-options    ">
                        <li>
                          <div className=" option_but " onClick={nextBasicDetails}>
                            <input
                              type="radio"
                              id="326"
                              value="326"
                              name="check3"
                            />
                            <label
                              for="326"
                              className=" align-items-center endQuest"
                            >
                              <div> Yes</div>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div className="option_but" onClick={nextBasicDetails}>
                            <input type="radio" id="327" name="check3" value="327"/>
                            <label for="327" className="endQuest">
                              <div> No</div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className=""></div>
                  {/* <!--   <div className="swiper-button-prev"></div> --> */}
                  <div className="mt-2 mb-4 text-center">
                    <div>
                      {" "}
                      <button
                        className="back-btn mainbckBtn bi bi-chevron-left"
                        type="button"
                        value={bacKbtnId}
                        onClick={prevQuestion}
                        disabled={btnDisable}
                      >
                        {/* <i className="bi bi-chevron-left"></i>  */}
                        Back
                      </button>{" "}
                    </div>
                  </div>
                </div>

                <div className="mt-2 mb-3 justify-content-between  align-items-center text-center slide-up-now">
                  <div className="ms-auto d-flex justify-content-center g-2 pe-lg-3 pe-md-3 ps-sm-0 pe-0 mb-lg-0 mb-md-0 mb-sm-4  mb-4">
                    <div>
                      {/* <img src={excellent_review} alt="" /> */}
                      <img className="ps-2" height="28" src={ssl} alt="" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-lg-4 pt-md-5 pt-sm-3 pt-3">
              <div className="d-flex text-danger">
                <div className="pe-2">
                  <i className="bi bi-question-circle"></i>
                </div>
                <div>
                  <p>
                    Why? Because we believe everyone deserves to know what
                    they’re owed—without barriers. Banks have taken enough from
                    you already. Don’t miss this opportunity to take control of
                    your finances and get the justice you deserve.
                  </p>
                  <p className={` text-black ${questTwo}`}>
                  {/* *Our Solicitors work on a no win no fee basis (subject to exclusions, for full details click on: {" "}
                    <a href= "https://myclaimgroup.co.uk/no-win-no-fee" target="_blank">
                    https://myclaimgroup.co.uk/no-win-no-fee</a>
                  )<br/> */}
                  ^Our Average Refund for Vanquis as of 20/08/2024
                  </p>
                  <p className={` text-black ${questThree}`}>
                  *Our Solicitors work on a no win no fee basis (subject to exclusions, for full details click on: {" "}
                    <a href= "https://myclaimgroup.co.uk/no-win-no-fee" target="_blank">
                    https://myclaimgroup.co.uk/no-win-no-fee</a>
                  )<br/>
                  {/* ^Our Average Refund for Vanquis as of 20/08/2024 */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Questions;
