import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import { MonthlyIncomeODv2 } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";

const MonthlyIncome = ({  
    trigger,
    validation,
    saveInputAns,
    backClick,
    clsName }) => {


    const [error, setError] = useState(false);

    const handleClick = async (e) => {
        let incomeMonthly = await trigger("monthlyIncome");
        let errorFlag = 0;
        if (!incomeMonthly) {
            errorFlag = 1;
        }
        if (errorFlag === 0) {
            setError("");
            GtmDataLayer({
                question: "",
            });
            saveInputAns(e);
        } else {
            setError("Please select your Monthly Income");
            return false;
        }
    };

    return (
        <div className={`qsSlider slidea13 ${clsName}`}>
            <h2 className="qs-title slide-up-now">
                When you took out your overdraft, what was your monthly income?
            </h2>
            {/* <div className="btn-option optinal-items optinal-items-payment slide-up-now">
                {[
                    { id: "£500", label: "Less than £500", value: "269", dataAnsLabel: "Less than £500" },
                    { id: "£1000", label: "£500 - £1000", value: "270", dataAnsLabel: "£500 - £1000" },
                    { id: "£1500", label: "£1000 - £1500", value: "271", dataAnsLabel: "£1000 - £1500" },
                    { id: "£2000", label: "£1500 - £2000", value: "272", dataAnsLabel: "£1500 - £2000" },
                    { id: "£3000", label: "£2500 - £3000", value: "273", dataAnsLabel: "£2500 - £3000" },
                    { id: "£4000", label: "£3000 - £4000", value: "274", dataAnsLabel: "£3000 - £4000" },
                    { id: "£4000above", label: "More than £4000", value: "275", dataAnsLabel: "More than £4000" },
                ].map((option) => (
                    <div className="option_but mb-3">
                        <RadioButtonQuest
                            key={option.id}
                            id={option.id}
                            labelClassName="align-items-center next13"
                            labelName={option.label}
                            value={option.value}
                            name="monthlyincome"
                            onClick={optionClicked}
                            dataQuestId={101}
                            dataAnsId={option.value}
                            dataAnsLabel={option.dataAnsLabel}
                        />
                    </div>
                ))}
            </div> */}
            <div className="my-4  forms-body slide-up-now">
                <SelectBox
                    type=""
                    className="form-control"
                    name="monthlyIncome"
                    OptionValue={MonthlyIncomeODv2}
                    onChange={() => setError("")}
                    myRef={validation({
                        required: true,
                    })}
                />
                {error && (
                    <span className="error_msg mt-1">{error}</span>
                )} 
            </div>
            <div className="row  mb-3  ">
                <div className="col-12">
                    <button 
                        className="btn-comm2    w-100  next7"
                        type="button"
                        name="monthlyincome"
                        onClick={handleClick} 
                        data-quest-id={101} >
                        {/* <span className="btntext"> Continue </span> */}
                        Continue
                    </button>
                </div>
            </div>
            <div className="mt-2 mb-5 text-center">
                <div>
                    <button
                        type="button"
                        name="recieveanyincome"
                        className="back-btn mainbckBtn bi bi-chevron-left"
                        id="back12"
                        onClick={backClick}
                    >
                       Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MonthlyIncome;
