import React, { useEffect, useState, useRef, useContext } from "react";
import Questions from "../Includes/Layouts/UFC_DC_V1/Questions";
import PersonalDetails from "../Includes/Layouts/UFC_DC_V1/PersonalDetails";
import ContactInfo from "../Includes/Layouts/UFC_DC_V1/ContactInfo";
import Signature from "../Includes/Layouts/UFC_DC_V1/Signature";
import LenderSection from "../Includes/Layouts/UFC_DC_V1/LenderSection";
import { FormProvider, useForm } from "react-hook-form";
import Loader from "../Includes/Layouts/UFC_DC_V1/Loader";
import { blacklist } from "../../Hooks/blacklist";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import { useQueryString } from "../../Hooks/useQueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { useSignature } from "../../Hooks/useSignature";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useAddressApi } from "../../Hooks/useAddressApi";
import { usePageLoad } from "../../Hooks/usePageLoad";
import { getPageName } from "../../Constants/PageNames";

const FormUFC_DC_V1 = () => {
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    watch,
    handleSubmit,
  } = formMethods;

  const { updateMilestone } = useUpdateMilestone();
  const { saveSignature } = useSignature();
  const { formDataIngestion } = useDataIngestion();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [showLenderSection, setShowLenderSection] = useState("show");
  const [showQuestion, setShowQuestion] = useState("hide");
  const [showPersonalDetails, setShowPersonalDetails] = useState("hide");
  const [showContactInfo, setShowContactInfo] = useState("hide");
  const [showSignature, setShowSignature] = useState("hide");
  const splitForm = useRef(null);
  const [showLoader, setShowLoader] = useState("hide");
  const [signatureDataURL, setSignatureDataURL] = useState("");
  const [blacklisted, setBlacklisted] = useState([]);
  const field = "pid";
  const { getBlacklist } = blacklist();
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const [f_name, setF_name] = useState("");
  const [selectedBank, setSelectedBank] = useState([]);
  const [estimatedRefund, setEstimatedRefund] = useState("");
  const [unaffordableRefund, setUnaffordableRefund] = useState("");
  const [agreement, setAgreement] = useState(true);
  const signPadRef = useRef();
  const { savePageLoad, calledOnce } = usePageLoad();
  const splitName = localStorage.getItem("split_name");

  const form = splitForm.current;
  const values = getValues();
  const formDatas = values;
  // let UcFname = formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);
  // setF_name(UcFname);
  const { addressCheck } = useAddressApi();
  const [question118,SetQuestion118] = useState("");
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };

  const uuid = getUuid();

  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response);
      }
    })();
  }, []);

  const formSubmit = async (msg) => {
    const formDatas = getValues();

    if (msg === "form") {
      if (formDatas.address1 !== "") {
        const txtUdprn = form["txtUdprn"].value;
        const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
        const txtPz_mailsort = form["txtPz_mailsort"].value;
        const txtStreet = form["txtStreet"].value;
        const txtAddress3 = form["txtAddress3"].value;
        const txtDistrict = form["txtDistrict"].value;

        formDatas.txtDistrict = txtDistrict;
        formDatas.txtAddress3 = txtAddress3;
        formDatas.txtUdprn = txtUdprn;
        formDatas.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
        formDatas.txtPz_mailsort = txtPz_mailsort;
        formDatas.txtStreet = txtStreet;
      }

      formDatas.lender = [selectedBank[0]];
      formDatas.bud_lender = selectedBank[0].value;
      formDatas.lenders = selectedBank[0].value;
      formDatas.question114 = estimatedRefund;
      if (estimatedRefund == "318") {
        formDatas.question115 = unaffordableRefund;
      }
      if (estimatedRefund == "318" && unaffordableRefund == "320") {
      formDatas.question118 = question118;
      }
      formDatas.page_name = "UFC_DC_V1";
      formDatas.pCode_manual = form["pCode_EntryType"].value;
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        localStorage.setItem("fName", formDatas.txtFName);
        localStorage.setItem("lName", formDatas.txtLName);
        localStorage.setItem("pCode", formDatas.txtPostCode);
        localStorage.setItem("addressLine1", formDatas.txtHouseNumber);
        localStorage.setItem("dobYr", formDatas.DobYear);
        localStorage.setItem("dobMon", formDatas.DobMonth);
        localStorage.setItem("dobDy", formDatas.DobDay);
        localStorage.setItem("addressLine2", formDatas.txtAddress2);
        localStorage.setItem("postTown", formDatas.txtTown);
        localStorage.setItem("country", formDatas.txtCountry);
        localStorage.setItem("title", formDatas.lstSalutation);
        localStorage.setItem("addressLine3", formDatas.txtAddress3);
        localStorage.setItem("county", formDatas.txtCounty);
        localStorage.setItem(
          "district",
          formDatas.txtDistrict ? formDatas.txtDistrict : ""
        );
        let UcFname =
          formDatas.txtFName.charAt(0).toUpperCase() +
          formDatas.txtFName.slice(1);

        localStorage.setItem("UcFname", UcFname);

        localStorage.setItem("formData", JSON.stringify(formDatas));
        localStorage.setItem("queryString", queryString);

        localStorage.setItem(
          "creditstatus",
          formDatas.question114 == "318" ? "Pass" : "Fail"
        );

        const formSUbmitResult = await formDataIngestion(
          visitorParameters.visitor_parameters,
          formDatas,
          "split_form_submit",
          "UFC_DC_V1",
          queryString,
          visitorParameters.data
        );
        if (formSUbmitResult.data.status === "Success") {
          const data = {
            uuid,
            page_name: getPageName("personal"),
            status: "completed",
            source: "regular",
          };
          (async () => {
            await savePageLoad(
              visitorParameters.visitor_parameters,
              data,
              "visited_status",
              splitName,
              queryString,
              visitorParameters.data
            );
          })();
          updateMilestone(
            visitorParameters.visitor_parameters.uuid,
            "lead",
            "live"
          );
          const signdata = {
            uuid,
            page_name: getPageName("sign"),
            status: "visited",
            source: "regular",
          };
          (async () => {
            await savePageLoad(
              visitorParameters.visitor_parameters,
              signdata,
              "visited_status",
              splitName,
              queryString,
              visitorParameters.data
            );
          })();
          if (estimatedRefund == "318") {
            addressCheck(visitorParameters.visitor_parameters, formDatas);
          }
        }
      } else {
        return "Fail";
      }
    }
    if (msg === "signature") {
      setShowLoader("show");
      const canvasDataURL = signPadRef.current
        .getCanvas()
        .toDataURL("image/png");

      let formDatas = JSON.parse(localStorage.getItem("formData"));
      formDatas.user_sign = canvasDataURL;
      formDatas.agreement = agreement;
      localStorage.setItem("formData", JSON.stringify(formDatas));

      if (canvasDataURL) {
        const response = await saveSignature(
          canvasDataURL,
          visitorParameters.visitor_parameters,
          formDatas,
          visitorParameters.data,
          queryString,
          "signature_store"
        );

        if (response.data.status === "Success") {
          const data = {
            uuid,
            page_name: getPageName("sign"),
            status: "completed",
            source: "regular",
          };
          (async () => {
            await savePageLoad(
              visitorParameters.visitor_parameters,
              data,
              "visited_status",
              splitName,
              queryString,
              visitorParameters.data
            );
          })();
          updateMilestone(
            visitorParameters.visitor_parameters.uuid,
            "signature",
            "live"
          );
          pageChange();
        }
      } else {
        return "Fail";
      }
    }
  };

  const pageChange = () => {
    let dynamicRoute = "credit-Analyze";
    // let dynamicRoute = "Edit_details";
    if (estimatedRefund != "318") {
      dynamicRoute = "questionaireUFC_DC_V1";
    }
    let blacklistFlag = false;
    blacklisted.map((value, index) => {
      if ("email" == value.type && value.value == formDatas.txtEmail) {
        blacklistFlag = true;
      }

      if ("phone" == value.type && value.value == formDatas.txtPhone) {
        blacklistFlag = true;
      }
    });

    if (blacklistFlag) {
      window.scrollTo(0, 0);
      history.push(
        "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            history.push("/fbpixel?split_name=UFC_DC_V1");
          } else {
            window.scrollTo(0, 0);
            history.push(
              `/${dynamicRoute}?uuid=${visitorParameters.visitor_parameters.uuid}`
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            `/${dynamicRoute}?uuid=${visitorParameters.visitor_parameters.uuid}`
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          `/${dynamicRoute}?uuid=${visitorParameters.visitor_parameters.uuid}`
        );
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.pageYOffset;
      const pageBottom = pageTop + window.innerHeight;
      const tags = document.querySelectorAll(".fadein-eff");

      tags.forEach((tag) => {
        const tagTop = tag.getBoundingClientRect().top + pageTop;

        if (tagTop < pageBottom) {
          tag.classList.add("visible");
        } else {
          tag.classList.remove("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        >
          <LenderSection
            setShowLenderSection={setShowLenderSection}
            showLenderSection={showLenderSection}
            setShowQuestion={setShowQuestion}
            setShowLoader={setShowLoader}
            setSelectedBank={setSelectedBank}
            uuid={uuid}
          />
          <Loader showLoader={showLoader} />
          <Questions
            setShowPersonalDetails={setShowPersonalDetails}
            setShowQuestion={setShowQuestion}
            showQuestion={showQuestion}
            setShowLoader={setShowLoader}
            selectedBank={selectedBank}
            setEstimatedRefund={setEstimatedRefund}
            setUnaffordableRefund={setUnaffordableRefund}
            SetQuestion118={SetQuestion118}
          />
          <PersonalDetails
            showPersonalDetails={showPersonalDetails}
            setShowPersonalDetails={setShowPersonalDetails}
            setShowQuestion={setShowQuestion}
            setShowContactInfo={setShowContactInfo}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            setShowLoader={setShowLoader}
            setF_name={setF_name}
          />
          <ContactInfo
            showContactInfo={showContactInfo}
            setShowPersonalDetails={setShowPersonalDetails}
            setShowSignature={setShowSignature}
            setShowContactInfo={setShowContactInfo}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            setShowLoader={setShowLoader}
            formSubmit={formSubmit}
          />
          <Signature
            f_name={f_name}
            showSignature={showSignature}
            formSubmit={formSubmit}
            setSignatureDataURL={setSignatureDataURL}
            signPadRef={signPadRef}
            setAgreement={setAgreement}
            estimatedRefund={estimatedRefund}
            unaffordableRefund={unaffordableRefund}
            question118={question118}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default FormUFC_DC_V1;
