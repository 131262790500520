import React, { useState, useRef, useEffect, useReducer, useContext } from 'react';
import "../../assets/QuestionaireUFC_DC_V1/css/questionnaireUFC_DC_V1.scss";
import "../../assets/QuestionaireUFC_DC_V1/css/custom.scss";
import { useForm } from "react-hook-form";
import { wereUalreadyPayingOD, ApplythisFollowingOD } from '../../Constants/Questions';
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import OccasionalUnforeseenEmergency from '../Includes/Layouts/QuestionaireUFC_DC_V2/OccasionalUnforeseenEmergency';
import ApplythisFollowing from '../Includes/Layouts/QuestionaireUFC_DC_V2/ApplythisFollowing';
import OfferLoans from '../Includes/Layouts/QuestionaireUFC_DC_V2/OfferLoans';
import OpeningCreditLimit from '../Includes/Layouts/QuestionaireUFC_DC_V2/OpeningCreditLimit';
import EverIncreCredLimit from '../Includes/Layouts/QuestionaireUFC_DC_V2/EverIncreCredLimit';
import BankActedIrresponsibly from "../Includes/Layouts/QuestionaireUFC_DC_V2/BankActedIrresponsibly";
import HighestCreditLimit from '../Includes/Layouts/QuestionaireUFC_DC_V2/HighestCreditLimit';
import ExceedCreditLimit from "../Includes/Layouts/QuestionaireUFC_DC_V2/ExceedCreditLimit";
import HowOftenExceedCredit from '../Includes/Layouts/QuestionaireUFC_DC_V2/HowOftenExceedCredit';
import OverLimitCharges from '../Includes/Layouts/QuestionaireUFC_DC_V2/OverLimitCharges';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import { useHistory } from "react-router-dom";
import { useQuestionnaire } from '../../Hooks/useQuestionnaire';
import DidTakeFurtherBorrowing from '../Includes/Layouts/QuestionaireUFC_DC_V2/DidTakeFurtherBorrowing';
import SufferedFinancialHardship from '../Includes/Layouts/QuestionaireUFC_DC_V2/SufferedFinancialHardship';
import RecieveAnyIncome from '../Includes/Layouts/QuestionaireUFC_DC_V2/RecieveAnyIncome';
import MonthlyIncome from '../Includes/Layouts/QuestionaireUFC_DC_V2/MonthlyIncome';
import Dependents from '../Includes/Layouts/QuestionaireUFC_DC_V2/Dependents';
import WereAlreadyPaying from '../Includes/Layouts/QuestionaireUFC_DC_V2/WereAlreadyPaying';
import IncomeAndExpV2 from '../Includes/Layouts/QuestionaireUFC_DC_V2/IncomeAndExpV2';
import FurtherBorrowing from '../Includes/Layouts/QuestionaireUFC_DC_V2/FurtherBorrowing';
import StruggledRepayments from "../Includes/Layouts/QuestionaireUFC_DC_V2/StruggledRepayments";
import MaintainedCreditBalance from "../Includes/Layouts/QuestionaireUFC_DC_V2/MaintainedCreditBalance";
import StuckInOverdraft from "../Includes/Layouts/QuestionaireUFC_DC_V2/StuckInOverdraft";
import HowStuckAffects from "../Includes/Layouts/QuestionaireUFC_DC_V2/HowStuckAffects";
import Footer from '../Includes/Layouts/UFC_DC_V2/Footer';
import HighestNum from "../Includes/Layouts/QuestionaireUFC_DC_V2/HighestNum";
import { usePageLoad } from "../../Hooks/usePageLoad";
import { getPageName } from "../../Constants/PageNames";
import QueryStringContext from "../../Contexts/QueryString";

const initialState = {
    title1: "show",
    OccasionalUnforeseenEmergency: "show",
    applythisFollowing: "hide",
    highestNum:"hide",
    offerLoans: "hide",
    openingCreditLimit: "hide",
    everIncresedCredLimit: "hide",
    bankActedIrresponsibly: "hide",
    highestCreditLimit: "hide",
    exceedCredLimit: "hide",
    oftenExceedCredit: "hide",
    overLimit: "hide",
    title2: "hide",
    financialHardship: "hide",
    // thinkBackbtn: "hide",
    recieveAnyIncome: "hide",
    monthlyIncome: "hide",
    dependents: "hide",
    wereAlreadyPaying: "hide",
    incmAndExp: "hide",
    title3: "hide",
    furtherBorrowing: "hide",
    struggledRepayments: "hide",
    maintainedCreditBalance: "hide",
    stuckInOverdraft: "hide",
    howStuckAffects: "hide",
    exceedCredLimitBck: "",
    financialhardshipBck: "",
    dependentsBck: "",
    didTakeFurtherBorrowing: "hide",
    struggledRepaymentsBck: "",
    highestnumBck: "",
};
const FormReducer = (state, action) => {
    switch (action.type) {
        case "showSlide": {
            if (action.payload.clickedSlide.slide == "OccasionalUnforeseenEmergency") {
                return {
                    ...state,
                    OccasionalUnforeseenEmergency: "hide",
                    applythisFollowing: "show",
                };
            }
            else if (action.payload.clickedSlide.slide == "applythisFollowing" && action.payload.clickedSlide.slide_value == true ) {
                return {
                    ...state,
                    highestNum: "show",
                    applythisFollowing: "hide",
                };
            } else if (action.payload.clickedSlide.slide == "applythisFollowing" && action.payload.clickedSlide.slide_value == false ) {
                return {
                    ...state,
                    offerLoans: "show",
                    applythisFollowing: "hide",
                };
            }else if (action.payload.clickedSlide.slide == "highestNum") {
                return {
                    ...state,
                    offerLoans: "show",
                    highestNum: "hide",
                };
            }
            else if (action.payload.clickedSlide.slide == "offerLoans") {
                return {
                    ...state,
                    offerLoans: "hide",
                    openingCreditLimit: "show",
                };
            }
            else if (action.payload.clickedSlide.slide == "openingCreditLimit") {

                return {
                    ...state,
                    openingCreditLimit: "hide",
                    everIncresedCredLimit: "show",
                };
            } else if (action.payload.clickedSlide.slide == "everincresedCredLimit" && action.payload.clickedSlide.slide_value == "247") {

                return {
                    ...state,
                    bankActedIrresponsibly: "show",
                    everIncresedCredLimit: "hide",
                };
            } else if (action.payload.clickedSlide.slide == "everincresedCredLimit" && action.payload.clickedSlide.slide_value == "248") {

                return {
                    ...state,
                    exceedCredLimit: "show",
                    everIncresedCredLimit: "hide",
                };
            } else if (action.payload.clickedSlide.slide == "bankActedIrresponsibly") {

                return {
                    ...state,
                    bankActedIrresponsibly: "hide",
                    highestCreditLimit: "show",
                };
            } else if (action.payload.clickedSlide.slide == "highestcreditLimit") {
                return {
                    ...state,
                    highestCreditLimit: "hide",
                    exceedCredLimit: "show",
                };
            } else if (action.payload.clickedSlide.slide == "exceedCredLimit" && action.payload.clickedSlide.slide_value == "251") {
                return {
                    ...state,
                    exceedCredLimit: "hide",
                    oftenExceedCredit: "show",
                };
            } else if (action.payload.clickedSlide.slide == "exceedCredLimit" && action.payload.clickedSlide.slide_value == "252") {
                return {
                    ...state,
                    title2: "show",
                    financialHardship: "show",
                    exceedCredLimit: "hide",
                    overLimit: "hide",
                    title1: "hide"
                };
            } else if (action.payload.clickedSlide.slide == "oftenexceedCredit") {
                return {
                    ...state,
                    oftenExceedCredit: "hide",
                    overLimit: "show",
                };
            } else if (action.payload.clickedSlide.slide == "overlimit") {

                return {
                    ...state,
                    title1: "hide",
                    overLimit: "hide",
                    financialHardship: "show",
                    title2: "show",
                };
            } else if (action.payload.clickedSlide.slide == "financialhardship") {

                return {
                    ...state,
                    financialHardship: "hide",
                    recieveAnyIncome: "show",
                };
            }
            //  else if (action.payload.clickedSlide.slide == "thinkBackbtn") {
            //   return {
            //     ...state,
            //     recieveAnyIncome: "show",
            //   };
            // }
            else if (action.payload.clickedSlide.slide == "recieveanyincome" && action.payload.clickedSlide.slide_value == "267") {

                return {
                    ...state,
                    recieveAnyIncome: "hide",
                    monthlyIncome: "show",
                };
            } else if (action.payload.clickedSlide.slide == "recieveanyincome" && action.payload.clickedSlide.slide_value == "268") {

                return {
                    ...state,
                    recieveAnyIncome: "hide",
                    dependents: "show",
                };
            } else if (action.payload.clickedSlide.slide == "monthlyincome") {

                return {
                    ...state,
                    monthlyIncome: "hide",
                    dependents: "show",
                };
            } else if (action.payload.clickedSlide.slide == "dependents") {

                return {
                    ...state,
                    dependents: "hide",
                    wereAlreadyPaying: "show",
                };
            } else if (action.payload.clickedSlide.slide == "wereAlreadyPaying") {

                return {
                    ...state,
                    wereAlreadyPaying: "hide",
                    incmAndExp: "show",
                };
            } else if (action.payload.clickedSlide.slide == "incmAndexp") {

                return {
                    ...state,
                    incmAndExp: "hide",
                    didTakeFurtherBorrowing: "show",
                    title2: "hide",
                    title3: "show",
                };
            } else if (action.payload.clickedSlide.slide == "didtakefurtherborrowing" && action.payload.clickedSlide.slide_value == "312") {
                return {
                    ...state,
                    didTakeFurtherBorrowing: "hide",
                    furtherBorrowing: "show"
                };
            } else if (action.payload.clickedSlide.slide == "didtakefurtherborrowing" && action.payload.clickedSlide.slide_value == "313") {

                return {
                    ...state,
                    didTakeFurtherBorrowing: "hide",
                    struggledRepayments: "show"
                };
            } else if (action.payload.clickedSlide.slide == "furtherborrowing") {

                return {
                    ...state,
                    furtherBorrowing: "hide",
                    struggledRepayments: "show"
                };
            } else if (action.payload.clickedSlide.slide == "struggledrepayments") {

                return {
                    ...state,
                    struggledRepayments: "hide",
                    maintainedCreditBalance: "show"
                };
            } else if (action.payload.clickedSlide.slide == "maintainedcreditbalance") {

                return {
                    ...state,
                    maintainedCreditBalance: "hide",
                    stuckInOverdraft: "show"
                };
            } else if (action.payload.clickedSlide.slide == "stuckinoverdraft" && action.payload.clickedSlide.slide_value != "293") {

                return {
                    ...state,
                    stuckInOverdraft:"hide",
                    howStuckAffects: "show"
                };
            }
            else {
                return {
                    ...state,
                };
            }
        }
        case "previousSlides": {
            if (action.payload.previousSlide.slide == "OccasionalUnforeseenEmergency") {
                return {
                    ...state,
                    applythisFollowing: "hide",
                    OccasionalUnforeseenEmergency: "show",
                };
            }
            else if (action.payload.previousSlide.slide == "applythisFollowing" && state.highestnumBck.some(option => option.id === 308)) {
                return {
                    ...state,
                    applythisFollowing: "show",
                    highestNum: "hide",
                };
            }else if (action.payload.previousSlide.slide == "applythisFollowing" && state.highestnumBck.some(option => option.id !== 308)){
                return {
                  ...state,
                  applythisFollowing: "show",
                  offerLoans: "hide",
                };
              } else if (action.payload.previousSlide.slide == "highestNum") {
                return {
                  ...state,
                  highestNum: "show",
                  offerLoans: "hide",
                };
              }
            else if (action.payload.previousSlide.slide == "offerLoans") {
                return {
                    ...state,
                    offerLoans: "show",
                    openingCreditLimit: "hide",
                };
            }
            else if (action.payload.previousSlide.slide == "openingCreditLimit") {
                return {
                    ...state,
                    everIncresedCredLimit: "hide",
                    openingCreditLimit: "show",
                };
            }
            else if (action.payload.previousSlide.slide == "everincresedCredLimit" && state.exceedCredLimitBck == "247") {
                return {
                    ...state,
                    bankActedIrresponsibly: "hide",
                    everIncresedCredLimit: "show",
                };
            } else if (action.payload.previousSlide.slide == "everincresedCredLimit" && state.exceedCredLimitBck == "248") {
                return {
                    ...state,
                    exceedCredLimit: "hide",
                    everIncresedCredLimit: "show",
                };
            } else if (action.payload.previousSlide.slide == "bankActedIrresponsibly") {
                return {
                    ...state,
                    highestCreditLimit: "hide",
                    bankActedIrresponsibly: "show",
                };
            } else if (action.payload.previousSlide.slide == "highestcreditLimit") {
                return {
                    ...state,
                    exceedCredLimit: "hide",
                    highestCreditLimit: "show",
                };
            } else if (action.payload.previousSlide.slide == "exceedCredLimit" && state.financialhardshipBck == "251") {
                return {
                    ...state,
                    oftenExceedCredit: "hide",
                    exceedCredLimit: "show",
                };
            } else if (action.payload.previousSlide.slide == "exceedCredLimit" && state.financialhardshipBck == "252") {
                return {
                    ...state,
                    financialHardship: "hide",
                    title2: "hide",
                    exceedCredLimit: "show",
                    title1: "show"
                };
            } else if (action.payload.previousSlide.slide == "oftenexceedCredit") {
                return {
                    ...state,
                    overLimit: "hide",
                    oftenExceedCredit: "show",
                };
            } else if (action.payload.previousSlide.slide == "overlimit") {
                return {
                    ...state,
                    financialHardship: "hide",
                    title2: "hide",
                    overLimit: "show",
                    title1: "show"
                };
            }
            else if (action.payload.previousSlide.slide == "financialhardship") {
                return {
                    ...state,
                    recieveAnyIncome: "hide",
                    financialHardship: "show",
                };
            }
            // else if (action.payload.previousSlide.slide == "thinkBackbtn") {
            //   return {
            //     ...state,
            //     recieveAnyIncome: "hide",
            //     financialhardship: "show",
            //   };
            // } 
            else if (action.payload.previousSlide.slide == "recieveanyincome" && state.dependentsBck == "267") {
                return {
                    ...state,
                    monthlyIncome: "hide",
                    recieveAnyIncome: "show",
                };
            } else if (action.payload.previousSlide.slide == "recieveanyincome" && state.dependentsBck == "268") {
                return {
                    ...state,
                    dependents: "hide",
                    recieveAnyIncome: "show",
                };
            } else if (action.payload.previousSlide.slide == "monthlyincome") {
                return {
                    ...state,
                    dependents: "hide",
                    monthlyIncome: "show",
                };
            } else if (action.payload.previousSlide.slide == "dependents") {
                return {
                    ...state,
                    wereAlreadyPaying: "hide",
                    dependents: "show",
                };
            } else if (action.payload.previousSlide.slide == "wereAlreadyPaying") {
                return {
                    ...state,
                    incmAndExp: "hide",
                    wereAlreadyPaying: "show",
                };
            } else if (action.payload.previousSlide.slide == "incmAndexp") {
                return {
                    ...state,
                    didTakeFurtherBorrowing: "hide",
                    title3: "hide",
                    title2: "show",
                    incmAndExp: "show",
                };
            } else if (action.payload.previousSlide.slide == "furtherborrowing" && state.struggledRepaymentsBck == "312") {
                return {
                    ...state,
                    struggledRepayments: "hide",
                    furtherBorrowing: "show",
                };
            } else if (action.payload.previousSlide.slide == "didtakefurtherborrowing" && state.struggledRepaymentsBck == "313") {
                return {
                    ...state,
                    struggledRepayments: "hide",
                    didTakeFurtherBorrowing: "show",
                };
            } else if (action.payload.previousSlide.slide == "didtakefurtherborrowing") {
                return {
                    ...state,
                    furtherBorrowing: "hide",
                    didTakeFurtherBorrowing: "show",
                };
            } else if (action.payload.previousSlide.slide == "struggledrepayments") {
                return {
                    ...state,
                    maintainedCreditBalance: "hide",
                    struggledRepayments: "show",
                };
            } else if (action.payload.previousSlide.slide == "maintainedcreditbalance") {
                return {
                    ...state,
                    stuckInOverdraft: "hide",
                    maintainedCreditBalance: "show",
                };
            } else if (action.payload.previousSlide.slide == "stuckinoverdraft") {
                return {
                    ...state,
                    howStuckAffects: "hide",
                    stuckInOverdraft: "show",
                };
            }
        }
        default:
            return state;
    }
}
const QuestionaireUFC_DC_V2 = () => {
    const splitForm = useRef(null);
    const scrollRef = useRef([]);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const [previousSlide, setPreviousSlide] = useState([]);
    const [optionSelected] = useState([]);
    const [everIncreased, setEverIncreased] = useState("");
    const [exceedCredit, setExceedCredit] = useState("");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const query_string = localStorage.getItem("queryString");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { setFormData } = FormData();
    const { getUserInfo } = userInfo();
    const [splitName, setSplitName] = useState();
    const [splitNameForSkip, setSplitNameForSkip] = useState();
    const [percentage, SetPercentage] = useState(0);
    const [recieveIncomeback, setRecieveIncomeback] = useState("");
    const [stuckinOverdraftbck, setStuckinOverdraftbck] = useState("");
    const [strugleRepaymentsBck, setStrugleRepaymentsBck] = useState("");
    const [checklistOptions, setChecklistOptions] = useState([]);
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [highestNumBck, setHighestNumBck] = useState([]);
    const { updateMilestone } = useUpdateMilestone();
    const history = useHistory();
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    let [totalQuestArr, setTotalQuestArr] = useState([
        91, 92, 95, 98, 100, 101, 112, 103, 104, 105, 106, 3, 79, 80
      ]);
    state.exceedCredLimitBck = everIncreased;
    state.financialhardshipBck = exceedCredit;
    state.dependentsBck = recieveIncomeback;
    state.struggledRepaymentsBck = strugleRepaymentsBck;
    state.highestnumBck = highestNumBck;
    const scrollfrmv2div = useRef([]);
    const [questionCounter, setQuestionCounter] = useState(0);
    const { savePageLoad, calledOnce } = usePageLoad();
    const split_name = localStorage.getItem("split_name");
    const { queryString } = useContext(QueryStringContext);

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    useEffect(() => {
        setTimeout(() => {
            scrollfrmv2div.current.scrollIntoView({
                behavior: "smooth",
                top: 0,
            });
        }, 50);

        if (clickedSlide) {
            dispatch({ type: "showSlide", payload: { clickedSlide } });
            setQuestionCounter(questionCounter + 1);
        }

    }, [clickedSlide]);

    const previousSlideChange = (e) => {
        setQuestionCounter(questionCounter - 1);
        setPreviousSlide({ slide: e.target.getAttribute("name") });
        setTimeout(() => {
            scrollfrmv2div.current.scrollIntoView({
                behavior: "smooth",
                top: 0,
            });
        }, 50);
    };

    useEffect(() => {
        if (previousSlide) {
            dispatch({ type: "previousSlides", payload: { previousSlide } });
        }
    }, [previousSlide]);

    useEffect(() => {
        var value = questionCounter;
        if (value == 1) {
          SetPercentage(0);
        } else {
          SetPercentage(Math.round(((value - 1) * 100) / totalQuestArr.length));
        }
      }, [questionCounter]);




    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };
    const uuid = getUuid();


    useEffect(() => {
        if (!!uuid && !calledOnce) {
        const data = {
            uuid,
            page_name: getPageName("questionsV2"),
            status: "visited",
            source: "regular",
        };
        (async () => {
            await savePageLoad(
            visitorParameters.visitor_parameters,
            data,
            "visited_status",
            split_name,
            queryString,
            visitorParameters.data
            );
        })();
        }
    }, [uuid, calledOnce]);
    
    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            query_string,
            formdata
        );
    };

    const unQualifiedAnswer = (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let flag = false;
        let question_data = {};
    
        if (selectedQuest && selectedAns) {
          flag = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: "",
            input_answer: null,
          };
        }
    
        if (flag == true) {
          storeUnqualifiedQuestionnire(question_data);
        }
      };

      const storeUnqualifiedQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
          visitorParameters.visitor_parameters,
          question_data,
          "unqualified_question_store",
          visitorParameters.data,
          query_string,
          formdata
        );
      };

    const onSelectAnswer = async (e, param) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let anslabel = e.target.getAttribute("data-ans-label");
        const valuesreg = getValues();
        let flage = false;
        let question_data = {};
        console.log(selectedAns, selectedQuest);
    
        if ((selectedAns == "252" && selectedQuest == "95") || selectedAns && selectedQuest == "97") {
       
          setClickedSlide({ slide: e.target.name });
          totalQuestArr.push(selectedQuest);
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: null,
          };
        }
    
        if (e.target.name == "wereAlreadyPaying") {
          setClickedSlide({ slide: e.target.name });
          totalQuestArr.push(79);
          if (selectedQuest) {
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: "",
              input_answer: checklistOptions,
            };
          }
        }
        if (e.target.name == "furtherborrowing") {
          totalQuestArr.push(112);
          setClickedSlide({ slide: e.target.name });
          if (selectedQuest) {
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: "",
              input_answer: checkedOptions,
            };
          }
        }
        if (selectedAns && selectedQuest == 105) {
          totalQuestArr.push(105)
          setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: null,
          };
        }
        if (e.target.name == "applythisFollowing") {
          if (selectedQuest) {
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: anslabel,
              input_answer: optionSelected,
            };
          }
        
        const contains308 = optionSelected.some(option => option.id === 308);
        setClickedSlide({ slide: "applythisFollowing",slide_value : contains308 });
        }
        
        if (selectedAns && selectedQuest) {
          totalQuestArr.push(selectedQuest)
          setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: null,
          };
        }
        console.log(question_data);
    
        if (flage) {
          storeQuestionnire(question_data);
          let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
          if (_existingEntries == null) _existingEntries = [];
          let existingIndex = _existingEntries.findIndex(
            (entry) => entry.question_id === question_data.question_id
          );
          if (existingIndex !== -1) {
            _existingEntries.splice(existingIndex, 1);
          }
          _existingEntries.push(question_data);
          localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    
          if (selectedAns == 293 && selectedQuest == 105) {
            redirectToPreview();
          }
        }
      }

    const saveInputAns = async (e) => {
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let anslabel = e.target.getAttribute("data-ans-label");
        const valuesreg = getValues();
        let flage = false;
        let question_data = {};
        let openingcrditlimit = valuesreg['creditLimit'];
        let creditLimitHigh = valuesreg['creditLimitHigh'];
        let thinkback = valuesreg['thinkback'];
        let stuckAffects = valuesreg['stuckAffects'].trim();
        let highestNum  = valuesreg['highestNumber'];
        let incomeMonthly = valuesreg['monthlyIncome'];

   
    
        if (selectedQuest == 91 && openingcrditlimit && e.target.name != "dontRemember") {
          setClickedSlide({ slide: e.target.name });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: openingcrditlimit,
          };
        } else if (selectedQuest == 91 && e.target.name == "dontRemember") {
          console.log("butY222");
          setClickedSlide({ slide: "openingCreditLimit" });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: "I Don't Remember",
          };
        } else if (selectedQuest == 94 && creditLimitHigh) {
          setClickedSlide({ slide: e.target.name });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: creditLimitHigh,
          };
        } else if (selectedQuest == 99 && thinkback) {
          setClickedSlide({ slide: e.target.name });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: thinkback,
          };
        } else if (selectedQuest == 106 && stuckAffects) {
          setClickedSlide({ slide: e.target.name });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: stuckAffects,
          };
        }else if (selectedQuest == 113 && highestNum) {
            totalQuestArr.push(selectedQuest);
            setClickedSlide({ slide: e.target.name });
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: "",
              input_answer: "£" + highestNum,
            };
          }else if (selectedQuest == 101 && incomeMonthly) {
            setClickedSlide({ slide: e.target.name });
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: "",
              input_answer: incomeMonthly,
            };
          }
      
        console.log(question_data);
    
        if (flage) {
          storeQuestionnire(question_data);
    
          let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
          if (_existingEntries == null) _existingEntries = [];
          let existingIndex = _existingEntries.findIndex(
            (entry) => entry.question_id === question_data.question_id
          );
          if (existingIndex !== -1) {
            _existingEntries.splice(existingIndex, 1);
          }
          _existingEntries.push(question_data);
          localStorage.setItem("questionData", JSON.stringify(_existingEntries));
          if (selectedQuest == "106" && stuckAffects) {
           
            redirectToPreview();
          }
        }
    
      };
    const saveSlide80 = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));

        // setCompletedQuestArr([...completedQuestArr, selectedQuest]);

        const values = getValues();
        let slide_array = [];


        if (checklistOptions.some((option) => option.id === 229)) {
            let answer229 = splitForm.current["question_228_229"].value;
            slide_array.push(["229", "£" + answer229]);
        }
        if (checklistOptions.some((option) => option.id === 230)) {
            let answer230 = splitForm.current["question_230"].value;
            slide_array.push(["230", "£" + answer230]);
        }
        if (checklistOptions.some((option) => option.id === 231)) {
            let answer231 = splitForm.current["question_231"].value;
            slide_array.push(["231", "£" + answer231]);
        }
        if (checklistOptions.some((option) => option.id === 232)) {
            let answer232 = splitForm.current["question_232"].value;
            slide_array.push(["232", "£" + answer232]);
        }
        if (checklistOptions.some((option) => option.id === 233)) {
            let answer233 = splitForm.current["question_233"].value;
            slide_array.push(["233", "£" + answer233]);
        }
        if (checklistOptions.some((option) => option.id === 234)) {
            let answer234 = splitForm.current["question_234"].value;
            slide_array.push(["234", "£" + answer234]);
        }

        if (checklistOptions.some((option) => option.id === 296)) {
            let answer296 = splitForm.current["question_296"].value;
            slide_array.push(["296", "£" + answer296]);
        }

        if (checklistOptions.some((option) => option.id === 297)) {
            let answer297 = splitForm.current["question_297"].value;
            slide_array.push(["297", "£" + answer297]);
        }

        if (checklistOptions.some((option) => option.id === 235)) {
            let answer235 = splitForm.current["question_235"].value;
            slide_array.push(["235", "£" + answer235]);
        }


        var question_data = {
            slide_80: ["80", JSON.stringify(slide_array)],
        };

        setClickedSlide({ slide: "incmAndexp" });
        // setTimeout(() => {
        //   setLoader3("show");

        //   setTimeout(() => {
        //     setLoader3("hide");
        //   }, 2000);
        // }, 0);
        storeQuestionnire(question_data);
        // console.log(question_data);


        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
        if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    };

    const redirectToPreview = async () => {
        updateMilestone(visitorParameters.visitor_parameters.uuid, "questions", "live");
        const data = {
            uuid,
            page_name: getPageName("questionsV2"),
            status: "completed",
            source: "regular",
        };
        (async () => {
            await savePageLoad(
            visitorParameters.visitor_parameters,
            data,
            "visited_status",
            split_name,
            queryString,
            visitorParameters.data
            );
        })();
        
        history.push(
          "/VerifyDataUFC_DC_V2?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      };

    return (
        <>
            <GetVisitorsParams/>
            <div className='questionnaireUFC_DC_V1'>
            <form
                        ref={splitForm}
                        name="split_form"
                        id="user_form"
                        autoComplete="off"
                    >
                <section className="banner  parallax-container">
                    <div className="d-lg-none d-md-none d-sm-block d-block money-bg-mobile">
                        <img src="../../assets/QuestionaireUFC_DC_V1/img/bg-money-sml.png" alt="" />
                    </div>
                    <div className="container" ref={scrollfrmv2div}>
                        <div>
                            <div className="brand">
                                <div className="logo pt-3">
                                    {/* <a href="index.html"> */}
                                    {" "}
                                    <img src="../../assets/QuestionaireUFC_DC_V1/img/logo-white.png" height={60} alt="" />
                                    {/* </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                        <div className="container pt-5 px-lg-2 px-md-2 px-sm-0 px-0  ">
                            <div className="inner-section fullqs-list">
                                <div className="inner1">
                                    <div className="mb-4">
                                        <h2 className="text-progress ">Question</h2>
                                        {/* <div
                                            className="progress"
                                            role="progressbar"
                                            aria-label="Info example"
                                            aria-valuenow={25}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            <div className="progress-bar bg-success" style={{ width: "10%" }}>
                                                10%
                                            </div>
                                        </div> */}
                                        <div className="progress" aria-label="Progress Bar" aria-valuemin={0} aria-valuemax={100} aria-valuenow={percentage}>
                                            {/* Progress bar that animates left to right */}
                                            <div
                                                className="progress-bar"
                                                style={{
                                                width: `${percentage}%`, // Dynamic width based on the percentage
                                                }}
                                            >
                                                {/* Display percentage value */}
                                                {percentage > 0 && `${percentage}%`}
                                            </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="mb-lg-4 mb-sm-3 mb-sm-3 mb-3">
                                    <h1
                                        className={`${state.title1} fw-bold text-lg-center  text-md-center text-sm-start text-start `}
                                        id="titl1"
                                    >
                                        {" "}
                                        About your overdraft facility{" "}
                                    </h1>
                                    <h1
                                        className={`${state.title2} fw-bold text-lg-center  text-md-center text-sm-start text-start`}
                                        id="titl2"
                                        style={{ display: "none" }}
                                    >
                                        Your financial commitments when you took the overdraft{" "}
                                    </h1>
                                    <h1
                                        className={`${state.title3} fw-bold text-lg-center  text-md-center text-sm-start text-start`}
                                        id="titl2"
                                        style={{ display: "none" }}
                                    >
                                        Your financial commitments during the duration of the overdraft{" "}
                                    </h1>
                                </div>
                                <div className="body-questionnaire mb-4">
                                    <div className="inner rounded-4">

                                        {/* Slides */}
                                        <OccasionalUnforeseenEmergency
                                            clsName={`${state.OccasionalUnforeseenEmergency}`}
                                            onSelectAnswer={onSelectAnswer}
                                            unQualifiedAnswer={unQualifiedAnswer}

                                        />
                                        {/* Slides */}
                                        <ApplythisFollowing
                                            clsName={`${state.applythisFollowing}`}
                                            questArr={ApplythisFollowingOD}
                                            optionSelected={optionSelected}
                                            backClick={previousSlideChange}
                                            onSelectAnswer={onSelectAnswer}
                                            setHighestNumBck={setHighestNumBck}
                                        />
                                        <HighestNum
                                            clsName={`${state.highestNum}`}
                                            trigger={trigger}
                                            validation={register}
                                            getValues={getValues}
                                            saveInputAns={saveInputAns}
                                            backClick={previousSlideChange}
                                        />
                                        {/* Slides */}
                                        <OfferLoans
                                            clsName={`${state.offerLoans}`}
                                            backClick={previousSlideChange}
                                            onSelectAnswer={onSelectAnswer}
                                            highestNumBck={highestNumBck}
                                        />
                                        {/* Slides */}
                                        <OpeningCreditLimit
                                            clsName={`${state.openingCreditLimit}`}
                                            trigger={trigger}
                                            validation={register}
                                            getValues={getValues}
                                            saveInputAns={saveInputAns}
                                            backClick={previousSlideChange}
                                        />

                                        {/* Slides */}
                                        <EverIncreCredLimit
                                            clsName={`${state.everIncresedCredLimit}`}
                                            setEverIncreased={setEverIncreased}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        {/* Slides */}
                                        <BankActedIrresponsibly
                                            clsName={`${state.bankActedIrresponsibly}`}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        // unQualifiedAnswer={unQualifiedAnswer}
                                        />

                                        {/* Slides */}
                                        <HighestCreditLimit
                                            clsName={`${state.highestCreditLimit}`}
                                            trigger={trigger}
                                            validation={register}
                                            getValues={getValues}
                                            saveInputAns={saveInputAns}
                                            backClick={previousSlideChange}
                                        />
                                        {/* Slides */}
                                        <ExceedCreditLimit
                                            clsName={`${state.exceedCredLimit}`}
                                            everIncreased={everIncreased}
                                            setExceedCredit={setExceedCredit}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        {/* Slides */}
                                        <HowOftenExceedCredit
                                            clsName={`${state.oftenExceedCredit}`}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />
                                        {/* Slides */}
                                        <OverLimitCharges
                                            clsName={`${state.overLimit}`}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />
                                        {/* Slides */}
                                        <SufferedFinancialHardship
                                            clsName={`${state.financialHardship}`}
                                            exceedCredit={exceedCredit}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        <RecieveAnyIncome
                                            clsName={`${state.recieveAnyIncome}`}
                                            setRecieveIncomeback={setRecieveIncomeback}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        <MonthlyIncome
                                            clsName={`${state.monthlyIncome}`}
                                            trigger={trigger}
                                            validation={register}
                                            getValues={getValues}
                                            saveInputAns={saveInputAns}
                                            backClick={previousSlideChange}
                                        />

                                        <Dependents
                                            clsName={`${state.dependents}`}
                                            recieveIncomeback={recieveIncomeback}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        <WereAlreadyPaying
                                            clsName={`${state.wereAlreadyPaying}`}
                                            onSelectAnswer={onSelectAnswer}
                                            questArr={wereUalreadyPayingOD}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            setError={setError}
                                            checklistOptions={checklistOptions}
                                            backClick={previousSlideChange}
                                            setChecklistOptions={setChecklistOptions}
                                        />

                                        <IncomeAndExpV2
                                            clsName={`${state.incmAndExp}`}
                                            validation={register}
                                            validationMsg={errors}
                                            getValues={getValues}
                                            trigger={trigger}
                                            setError={setError}
                                            splitForm={splitForm}
                                            questArr={wereUalreadyPayingOD}
                                            saveSlide80={saveSlide80}
                                            // scrollToElement={scrollToElement}
                                            backClick={previousSlideChange}
                                            checklistOptions={checklistOptions}
                                        />

                                        <DidTakeFurtherBorrowing
                                            clsName={`${state.didTakeFurtherBorrowing}`}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                            setStrugleRepaymentsBck={setStrugleRepaymentsBck}
                                        />

                                        <FurtherBorrowing
                                            clsName={`${state.furtherBorrowing}`}
                                            onSelectAnswer={onSelectAnswer}
                                            setCheckedOptions={setCheckedOptions}
                                            checkedOptions={checkedOptions}
                                            backClick={previousSlideChange}
                                        />

                                        <StruggledRepayments
                                            clsName={`${state.struggledRepayments}`}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                            strugleRepaymentsBck={strugleRepaymentsBck}
                                        />

                                        <MaintainedCreditBalance
                                            clsName={`${state.maintainedCreditBalance}`}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        <StuckInOverdraft
                                            clsName={`${state.stuckInOverdraft}`}
                                            setStuckinOverdraftbck={setStuckinOverdraftbck}
                                            onSelectAnswer={onSelectAnswer}
                                            backClick={previousSlideChange}
                                        />

                                        <HowStuckAffects
                                            clsName={`${state.howStuckAffects}`}
                                            saveInputAns={saveInputAns}
                                            backClick={previousSlideChange}
                                            stuckinOverdraftbck={stuckinOverdraftbck}
                                            trigger={trigger}
                                            validation={register}
                                            getValues={getValues}
                                            />
                                        {/* Slides */}
                                        <div className="mt-2 mb-3 d-lg-flex justify-content-center  align-items-center text-center slide-up-now">
                                            <div className=" pe-lg-3 pe-md-3 ps-sm-0 pe-0 mb-lg-0 mb-md-0 mb-sm-4  mb-4">
                                                {" "}
                                                {/* <img src="../../assets/QuestionaireUFC_DC_V1/img/excellent-review.svg" alt="" />{" "} */}
                                            </div>
                                            <div>
                                                {" "}
                                                <img height={28} src="../../assets/QuestionaireUFC_DC_V1/img/ssl.png" alt="" />{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </section>
                <Footer/>
                </form>
            </div>

        </>
    )


}

export default QuestionaireUFC_DC_V2;
