import React, { useState, useEffect, useContext } from "react";
import "../../assets/CreditAnalyse/css/creditcheck.scss";
import "../../assets/CreditAnalyse/css/credit-analysis.scss";
import Logowhite from "../../assets/CreditAnalyse/img/logo-white.e7860257.png";
import Secureicon from "../../assets/CreditAnalyse/img/security-safe-svgrepo-com.svg";
import Logowhite2 from "../../assets/CreditAnalyse/img/logo-white.png";
import AnalysisIcon from "../../assets/CreditAnalyse/img/analysis-icon.png";
import SearchGif from "../../assets/CreditAnalyse/img/searching.gif";
import sslpng from "../../assets/CreditAnalyse/img/ssl-png.png";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { queryString } from "../../Utility/QueryString";
import { useGetCreditAnalysisData } from "../../Hooks/useGetCreditAnalysisData";
import { usePageLoad } from "../../Hooks/usePageLoad";
import { getPageName } from "../../Constants/PageNames";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const CreditAnalyzeUFC_DC_V2 = () => {
  const [intialLoader, setIntialLoader] = useState("show");
  const [credPage, setCreditPage] = useState("hide");
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getCreditAnalysisData } = useGetCreditAnalysisData();
  const formData = JSON.parse(localStorage.getItem("formData")) || {};
  const [questionData, setQuestionData] = useState("");
  const [progress, setProgress] = useState({
    question_intervel: [0],
    analysis_intervel: [0],
    analysis_intervel_content: { 0: "Checking your finance agreement..." },
  });
  const [creditCheckFailed, setCreditCheckFailed] = useState(false);
  const query_uuid = new URLSearchParams(queryString).get("uuid");
  const [currentProgress, setCurrentProgress] = useState(0);
  const [endProgress, setEndProgress] = useState(10);
  const [userAnswers, setUserAnswers] = useState([]);
  const [otherCreditDetails, setOtherCreditDetails] = useState("");
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const { savePageLoad, calledOnce } = usePageLoad();
  const splitName = localStorage.getItem("split_name");
  const uuid = visitorParameters.visitor_parameters.uuid;
  const { updateMilestone } = useUpdateMilestone();

  useEffect(() => {
    setTimeout(() => {
      setIntialLoader("hide");
      setCreditPage("show");
    }, 2000);
    (async () => {
      const res = await getCreditAnalysisData(
        visitorParameters.visitor_parameters,
        formData,
        "get_credit_check_questions"
      );
      if (!!res.data.error && res.data.status.toLowerCase() !== "success") {
        console.log("error");
        
        let nextPage = localStorage.getItem("EditPage");
        if (nextPage == "visited") {
          history.push(
            "/questionaireUFC_DC_V2?uuid=" +
              visitorParameters.visitor_parameters.uuid
          );
        } else {
          console.log("Edit_details Redirect");
          // history.push(
          //   "/Edit_detailsUFC_DC_V2?uuid=" + visitorParameters.visitor_parameters.uuid
          // );
          history.push(
            "/questionaireUFC_DC_V2?uuid=" +
              visitorParameters.visitor_parameters.uuid
          );
        }
      } else {
        console.log("outside error");
        if (
          res.data.status == "Success" &&
          res?.data?.response?.vendor_response?.Info?.Data?.kba_data?.questions
            .length > 0
        ) {
          console.log("credit check success");
          const questionApiData =
            res?.data?.response?.vendor_response?.Info?.Data?.kba_data
              ?.questions;
          let questionIntervel = [0];
          let analysisIntervel = [0];
          let quest_int = Math.round(90 / questionApiData.length);
          for (let i = 1; i < questionApiData.length + 1; i++) {
            let q_int = quest_int * i;
            questionIntervel.push(q_int);
          }

          let analysis_int = Math.round(quest_int / 2);
          for (let j = 1; j < (questionApiData.length + 1) * 2; j++) {
            let a_int = analysis_int * j;
            analysisIntervel.push(a_int);
          }
          let analysisContent = [
            "Checking your finance agreement...",
            "Checking your finance agreement...",
            "Reviewing your PCP details...",
            "Verifying your car finance information...",
            "Analyzing your PCP contract...",
            "Processing your claim eligibility...",
          ];
          let analysisIntervelContent = {};
          analysisIntervel.forEach((value, index) => {
            analysisIntervelContent[value] =
              analysisContent[index] ||
              analysisContent[analysisContent.length - 1];
          });

          setProgress({
            question_intervel: questionIntervel,
            analysis_intervel: analysisIntervel,
            analysis_intervel_content: analysisIntervelContent,
          });
          setQuestionData(questionApiData);
          setOtherCreditDetails({
            referenceId:
              res?.data?.response?.vendor_response?.Info?.Data?.kba_data
                ?.referenceId,
          });
        } else {
          console.log("credit check not success");
          history.push(
            "/questionaireUFC_DC_V2?uuid=" +
              visitorParameters.visitor_parameters.uuid
          );
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (questionData) {
      setCurrentProgress(currentProgress + 1);
      setEndProgress(progress?.question_intervel?.[1] || endProgress);
      const updatedAnalysisIntervelNew = progress?.analysis_intervel?.slice(1);
      const updatedQuestionIntervelNew = progress?.question_intervel?.slice(1);
      setProgress((prevState) => ({
        ...prevState,
        question_intervel: updatedQuestionIntervelNew,
        analysis_intervel: updatedAnalysisIntervelNew,
      }));
    }
  }, [questionData]);

  useEffect(() => {
    if (currentProgress < endProgress) {
      const timer = setInterval(() => {
        setCurrentProgress(currentProgress + 1);
        if (currentProgress + 1 > progress?.analysis_intervel?.[0]) {
          const updatedAnalysisIntervel = progress.analysis_intervel.slice(1);
          setProgress((prevState) => ({
            ...prevState,
            analysis_intervel: updatedAnalysisIntervel,
          }));
        }
      }, 200);
      return () => clearInterval(timer);
    }
  }, [currentProgress, endProgress]);

  useEffect(() => {
    if (!!uuid && !calledOnce) {
      const data = {
        uuid,
        page_name: getPageName("credit"),
        status: "visited",
        source: "regular",
      };
      (async () => {
        await savePageLoad(
          visitorParameters.visitor_parameters,
          data,
          "visited_status",
          splitName,
          queryString,
          visitorParameters.data
        );
      })();
    }
  }, [uuid, calledOnce]);

  const radioBtnClick = async (e) => {
    let userSelectedAnswer = {
      questionId: parseInt(e.target.name.split("_")[1]),
      answer: [parseInt(e.target.value)],
    };

    setUserAnswers((prevUserAnswer) => [...prevUserAnswer, userSelectedAnswer]);
    let optionName = questionData?.[0]?.choices.find(
      (choice) => choice.choiceId == e.target.value
    ).choiceText;

    let data = { ...userSelectedAnswer, optionName: optionName };
    data.refId = otherCreditDetails.referenceId;
    data.title = questionData?.[0]?.questionText;
    data.options = questionData?.[0]?.choices;
    const res = await getCreditAnalysisData(
      visitorParameters.visitor_parameters,
      data,
      "credit_check_question_store"
    );
    let newQuestionSet = questionData.slice(1);
    setQuestionData(newQuestionSet);
    if (newQuestionSet.length == 0) {
      setCurrentProgress(currentProgress + 1);
      setEndProgress(100);
      let data = { question_answers: [...userAnswers, userSelectedAnswer] };
      data.referenceId = otherCreditDetails.referenceId;
      data.formData = formData;
      const res = await getCreditAnalysisData(
        visitorParameters.visitor_parameters,
        data,
        "credit_check_answers"
      );
      setFormSubmitSuccess(true);
    }
  };

  useEffect(() => {
    if (currentProgress == 100 && formSubmitSuccess) {
      // if (res.response.status != "Success" || email == "test922@922.com" ) {
      updateMilestone(uuid, "credit_check_analysis", "live", 1);
      history.push(
        "/questionaireUFC_DC_V2?uuid=" +
          visitorParameters.visitor_parameters.uuid
      );
      //   } else {
      //       history.push(
      //         "/questionaireUFC_DC_V1?uuid=" +
      //           visitorParameters.visitor_parameters.uuid
      //       );
      //   }
    }
  }, [currentProgress, formSubmitSuccess]);

  return (
    <>
      <GetVisitorsParams />
      <div className="Question_section_oneMB">
        <div className={`${intialLoader}`}>
          <div className="bg_preldr">
            <div className="container" style={{ marginTop: "-150px" }}>
              <div>
                <div className="brand">
                  <div className="logo pt-3 text-center">
                    <a href="index.html">
                      <img src={Logowhite} height={60} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="preloader">
              <div className="box">
                <div className="logo">
                  <img height={40} src={Secureicon} alt="" />
                </div>
              </div>
              <div className="box" />
              <div className="box" />
              <div className="box" />
              <div className="box" />
            </div>
          </div>
        </div>
      </div>
      <div className={`supagebg creditSection ${credPage}`}>
        <section className="creditanalysis">
          <header className="credheader">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <a href="index.html">
                    <img className="header-logo" src={Logowhite2} height={45} />
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 offset-lg-3">
                <div className="nobg d-flex align-items-center mt-3">
                  <div className="col-lg-2 col-3 text-center">
                    <img src={AnalysisIcon} height="50" alt="" />
                  </div>
                  <div className="col-lg-10 col-9 bor-left-cred  align-self-center">
                    <h4>Please wait while we complete your credit analysis.</h4>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <div className="progress-container mt-3">
                    <div
                      className="progress-circle"
                      style={{
                        background: `conic-gradient(#1DD9B3 ${
                          (currentProgress / 100) * 360
                        }deg, #1732a2 ${(currentProgress / 100) * 360}deg)`,
                      }}
                      id="progress-circle"
                    >
                      <div className="progress-inner">
                        <div>
                          <span id="progress-value" className="fw-bold">
                            {currentProgress}%
                          </span>
                          <div className="text2">COMPLETED</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 drop-in col-12 crediatailsloader text-center">
                {currentProgress < endProgress ||
                endProgress == 100 ||
                endProgress == 10 ? (
                  <div className="cred-slide1">
                    <img src={SearchGif} height="90" alt="" />
                    <p>Please wait ...</p>
                    <h3>
                      We may ask a few questions to verify that you're the
                      rightful claimant.
                    </h3>
                    <img src={sslpng} height="50" alt="" />
                  </div>
                ) : (
                  <div className="drop-in col-12 col-lg-8 offset-lg-2">
                    <h4>{questionData?.[0]?.questionText}</h4>
                    {questionData &&
                      questionData[0] &&
                      questionData[0].choices.map((item, index) => (
                        <button
                          name={`question_${questionData[0].questionId}`}
                          id={`question_${questionData[0].questionId}_${item.choiceId}`}
                          className="cred-but"
                          type="button"
                          value={item.choiceId}
                          onClick={radioBtnClick}
                        >
                          {item.choiceText}
                        </button>
                      ))}
                    <div className="col-12">
                      <img src={sslpng} height="50" alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CreditAnalyzeUFC_DC_V2;
